import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { h1 } from './../Headings';

const Section = styled.section`
    background: ${({ theme }) => theme.kindaWhite};
    margin-top: 96px;
    padding: 0;
    z-index: 1;

    @media (min-width: 1024px) and (max-height: 899px) {
        height: 400px;
    }

    @media (min-width: 1024px) and (min-height: 900px) {
        height: 500px;
    }

    @media (min-width: 1024px) {
        display: flex;
        align-items: flex-end;
        margin: 0;
        position: relative;
        background: none;

        ::before {
            content: '';
            position: absolute;
            left: 46px;
            right: 46px;
            top: 50%;
            transform: translateY(-50%);
            border-top: 8px dotted ${({ theme }) => theme.lightTeal};
        }
    }

    @media (min-width: 1280px) {
        ::before {
            left: 92px;
            right: 92px;
        }
    }
`;

const Breadcrumb = styled.div`
    text-align: center;
    z-index: 10;
    padding: 16px;

    > h5 {
        ${h1};
        text-transform: capitalize;
        margin: 0 0 16px;
    }

    > p {
        font-size: 1.5rem;
        font-weight: 700;
        color: ${({ theme }) => theme.darkTeal};
        margin: 0;
        text-transform: capitalize;

        > span {
            color: ${({ theme }) => theme.lightTeal};
        }
    }

    @media (min-width: 1024px) {
        padding: 32px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        align-self: end;
        grid-area: 1/1;

        > h5 {
            margin: 0;
        }
    }

    @media (min-width: 1280px) {
        padding-left: 64px;
        padding-right: 64px;
    }

    @media (min-width: 1280px) and (min-height: 899px) {
        padding-bottom: 64px;
    }
`;
export default function CommonHero({ title }) {
    return (
        <Section>
            <Breadcrumb>
                <h5>{title}</h5>

                <p>
                    Home | <span>{title}</span>
                </p>
            </Breadcrumb>
            <StaticImage
                src="../../images/hero-bg.png"
                alt=""
                loading="eager"
                placeholder="none"
                layout="fullWidth"
                css={css`
                    display: none;
                    position: absolute;
                    inset: 0;
                    z-index: 1;

                    @media (min-width: 1024px) {
                        display: block;
                    }
                `}
            />
        </Section>
    );
}
