import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { padding } from '../layout/GlobalStyles';
import { h1, h2, h3 } from './../Headings';

const Section = styled.section`
    margin: 0 auto;
    position: relative;
    padding: 64px 16px 96px;
    max-width: 1576px;
    border-top: 1px solid;
    border-image-slice: 1;
    border-image-source: ${({ theme }) => theme.gradient};

    > h3 {
        ${h1};
        width: fit-content;
        margin: 0 auto 64px;
    }

    h5,
    h6 {
        text-transform: capitalize;
        color: ${({ theme }) => theme.darkTeal};
    }

    h5 {
        ${h2};
    }

    h6 {
        ${h3};
    }

    ${padding};

    @media (min-width: 1280px) {
        padding: 128px 32px 148px;

        > h3 {
            margin-bottom: 128px;
        }
    }
`;

const One = styled.div`
    max-width: 1200px;
    margin: 0 auto;

    > :not(.gatsby-image-wrapper) {
        max-width: 775px;
    }
    h5 {
        margin: 0;
    }
    h6 {
        margin: 16px 0;
    }

    p {
        margin: 0;
    }

    @media (min-width: 1024px) {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    @media (min-width: 1280px) {
        h6 {
            margin: 32px 0;
        }
    }

    @media (min-width: 1440px) {
        max-width: 1275px;
    }

    @media (min-width: 1600px) {
        max-width: 1375px;
    }
`;

const Divider = styled.div`
    border-top: 1px solid;
    border-image-slice: 1;
    border-image-source: ${({ theme }) => theme.gradient};
    margin: 64px 0;

    @media (min-width: 1024px) {
        margin: 96px 0;
    }

    @media (min-width: 1280px) {
        margin: 128px 0;
    }
`;

const Grid = styled.div`
    display: grid;
    grid-template-columns: 300px;
    gap: 64px;
    justify-content: center;
    text-align: center;

    h5 {
        margin: 32px 0 0;
    }
    h6 {
        margin: 0;
    }

    @media (min-width: 768px) {
        gap: 96px;
    }

    @media (min-width: 1024px) {
        grid-template-columns: repeat(2, 300px);
        justify-content: space-between;
        max-width: 856px;
        margin: 0 auto;
        gap: 128px;
    }
`;

const oneImgStyle = css`
    width: 300px;
    border-radius: 50%;
    display: block;
    margin: 0 auto;
    filter: drop-shadow(0px 32px 64px rgba(33, 163, 185, 0.2));

    @media (min-width: 1024px) {
        flex-shrink: 0;
        margin: 0;
    }

    @media (min-width: 1280px) {
        width: 350px;
    }

    @media (min-width: 1440px) {
        width: 400px;
    }

    @media (min-width: 1600px) {
        width: 500px;
    }
`;

export default function MeetTeam() {
    const data = useStaticQuery(graphql`
        query TeamImages {
            allFile(
                filter: { relativeDirectory: { eq: "about" } }
                sort: { order: ASC, fields: name }
                limit: 4
            ) {
                nodes {
                    childImageSharp {
                        gatsbyImageData(width: 300, height: 300)
                    }
                }
            }
        }
    `);
    let images = data.allFile.nodes.map(node => getImage(node));

    const dentalAssistants = [
        { name: 'kristin', position: '' },
        { name: 'luz', position: '' },
        { name: 'virginia', position: '' },
        { name: 'jessica', position: '' },
    ];

    images = images.map((image, i) => ({
        name: dentalAssistants[i].name,
        position: dentalAssistants[i].position,
        image,
    }));

    return (
        <Section>
            <h3>Meet the rest of the team</h3>

            <One>
                <StaticImage
                    src="../../images/about/liz.jpg"
                    alt="liz land front desk coordinator"
                    css={oneImgStyle}
                />
                <div>
                    <h5>liz land</h5>
                    <h6>Front desk coordinator</h6>
                    <p>
                        Elizabeth - Liz for short - is Dr. Land's wife of over 30 years. She lives
                        on the island and helps keeps the ship afloat. She manages the patient
                        schedule, greets and welcomes new patients during exams, and ensures that
                        all of the clerical tasks of the office are in order. Liz loves to meet her
                        neighbors here in St. Croix and is especially excited about helping so many
                        of you walk away with the smile of your dreams!
                    </p>
                </div>
            </One>
            <Divider />
            <One>
                <div>
                    <h5>Cathy Mae Guevara</h5>
                    <h6>Dental Assistant</h6>
                    <p>
                        I was born and raised in the beautiful island of Trinidad & Tobago, moved to
                        St. Croix in 1999 and became a Certified Dental Assistant in 2005. I joined
                        the A-Team Land Orthodontics in 2017. I am a staff member of the American
                        Association of Orthodontics and loving every second of my job. I am NC Board
                        Certified DAII as a Clinical Orthodontic Assistant. I do the Intra oral
                        Scans with Invisalign Itero. On my free time I enjoy a good book, hanging
                        with my family and friends, traveling and beach days.
                    </p>
                </div>

                <StaticImage
                    src="../../images/about/cathy.jpg"
                    alt="cathy mae guevara dental assistant"
                    css={oneImgStyle}
                />
            </One>
            <Divider />

            <One>
                <StaticImage
                    src="../../images/about/michele.jpg"
                    alt="michele dental assistant"
                    css={oneImgStyle}
                />
                <div>
                    <h5>Michele</h5>
                    <h6>Dental Assistant</h6>
                    <p>
                        Michele started her career in Dentistry after completing her courses at the
                        Bryman College in Salt Lake City, Utah in 1991. <br /> She has enjoyed all
                        aspects of the profession and found her passion in Orthodontics while living
                        and working in Texas. As an American Board Certified Orthodontic assistant
                        she appreciates all challenges that come her way. <br /> Michele joined the
                        LandOrthodontic team in October of 2021 and looks forward to every day she
                        gets the opportunity to work with true professionals that know how to keep
                        work fun! <br /> In her spare time, Michele enjoys Scuba diving, soaking up
                        the sun and taking care of her rescue dogs.
                    </p>
                </div>
            </One>
            <Divider />

            <Grid>
                {images.map((profile, i) => (
                    <div key={i}>
                        <GatsbyImage
                            image={profile.image}
                            alt={`${profile.name} dental assistant`}
                            width={300}
                            css={css`
                                width: 230px;
                                border-radius: 50%;

                                @media (min-width: 768px) {
                                    width: 300px;
                                }
                            `}
                        />
                        <h5>{profile.name}</h5>
                        <h6>{profile.position}</h6>
                    </div>
                ))}
            </Grid>
        </Section>
    );
}
