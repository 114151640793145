import React from 'react';
import CommonHero from '../components/layout/CommonHero';
import Layout from '../components/layout/Layout';
import Seo from '../components/layout/Seo';
import DrPageSection from '../components/Sections/DrPageSection';
import MeetTeam from '../components/Sections/MeetTeam';

export default function AboutUs() {
    return (
        <Layout>
            <Seo
                title="About us"
                description="Dr.Herbert K. Land III is a board-certified orthodontist in St.Croix and the US Virgin Islands."
            />
            <CommonHero title="about us" />
            <DrPageSection />
            <MeetTeam />
        </Layout>
    );
}
