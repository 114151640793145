import styled from '@emotion/styled';
import React from 'react';
import AboutDr from '../AboutDr';
import { padding } from '../layout/GlobalStyles';

const Section = styled.section`
    padding: 32px 16px;

    > h1 {
        width: fit-content;
        margin: 0 auto 64px;
    }

    @media (min-width: 768px) {
        padding: 64px 16px;
    }
    ${padding};

    @media (min-width: 1280px) {
        padding: 128px 32px;

        > h1 {
            margin-bottom: 128px;
        }
    }
`;

const Box = styled.div`
    margin: 64px auto 0;
    max-width: 1270px;

    @media (min-width: 1280px) {
        margin-top: 128px;
    }
`;

export default function DrPageSection() {
    return (
        <Section>
            <h1>About Us</h1>
            <AboutDr description="DR. HERBERT K. LAND, III IS A BOARD-CERTIFIED ORTHODONTIST IN ST. CROIX AND THE US VIRGIN ISLANDS. HE HAS BEEN PRACTICING ORTHODONTICS AND SHAPING SMILES LIKE YOURS SINCE THE 1980S! HE WAKES UP EVERY DAY EXCITED TO HELP BOOST YOUR CONFIDENCE AND GIVE YOU THE LASTING, BEAUTIFUL SMILE YOU'VE ALWAYS DREAMED OF." />
            <Box>
                <p>
                    Dr. Land loves to meet new patients and their families every day to help them
                    get on track towards a bright and beautiful smile. After all, he became an
                    orthodontist because of how life-changing it was for him to get braces as a
                    young adult! Dr. Land has a unique understanding for patients seeking
                    orthodontic treatment and loves to help you attain your goals.
                    <br /> <br />
                    Originally from New Bern, North Carolina, Dr. Land and his wife Liz have owned a
                    home in St. Croix since 2009. After receiving a Bachelor of Science degree from
                    North Carolina State University in 1977, he enrolled at Emory University and was
                    awarded a Doctor of Dental Surgery in 1982. He then spent two years specializing
                    in orthodontics and dentofacial orthopedics, receiving his orthodontic
                    certificate from the University of Pennsylvania in 1984.
                    <br /> <br />
                    In 2000, Dr. Land was awarded the highest designation in orthodontics: a
                    Diplomate of the American Board of Orthodontics. This distinction is held by
                    only 20% of orthodontists in the United States. Dr. Land began sharing his
                    expertise with the next generation of orthodontists in 2004 when he began
                    teaching orthodontic resident students at the University of North Carolina
                    School of Dentistry as an Adjunct Professor.
                    <br /> <br />
                    Dr. Land is happily married to his wife, Liz, of over 30 years. They have a son
                    (Bradley), a daughter & son-in-law (Caroline & Corey), and a weiner dog named
                    Chipper. They love and appreciate the beauty and easy-going pace of life in St.
                    Croix!
                    <br /> <br />
                    PROFESSIONAL ORGANIZATIONS:
                </p>

                <ul>
                    <li>Diplomate - American Board of Orthodontics</li>
                    <li>American Association of Orthodontics</li>
                    <li>Southern Association of Orthodontics</li>
                    <li>North Carolina Dental Society</li>
                    <li>Wake County Dental Society</li>
                    <li>American Dental Association</li>
                </ul>
            </Box>
        </Section>
    );
}
